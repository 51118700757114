<script>
import { isCustomDomain, getAccountLogo } from "../utils/helpers";
export default {
  data() {
    return {
      username: "",
      password: "",
      dismissSecs: 15,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      errorMessage: "",
      variant: "warning",
      working: false,
      rememberMe: false,
      isCustomDomain
    };
  },
  mounted() {
    let self = this;

    let googleSSOMeta = document.createElement("meta");
    googleSSOMeta.name = "google-signin-client_id";
    googleSSOMeta.content = "760818933015-npocie5aurgs406ct2d8fne161dferov.apps.googleusercontent.com";
    document.head.appendChild(googleSSOMeta);

    let googleSSO = document.createElement('script')
    googleSSO.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleSSO);
    
    var rememberMeUser = $A.CheckRememberMe();
    if (rememberMeUser) {
      this.rememberMe = true;
      this.username = rememberMeUser;
    }
    if (this.$route.query.h !== undefined) {
      self.$A.UserService.LoginWithToken(this.$route.query.h, false, true);
    }
    this.$root.$on("alert::user", (message, type, timeout) => {
      self.errorMessage = message;
      self.variant = type;
      self.showDismissibleAlert = true;
      self.showAlert();
    });
  },
  computed: {
    accountLogo: function () {
      return getAccountLogo();
    }
  },
  methods: {
    ssoClicked: function(ssoMethod){
      // window.$A.MarketingService.SSOPreSignupEvent();
      const anchor = document.createElement('a');
      anchor.target = '_blank';
      anchor.innerText = 'SSO';
      if(ssoMethod === 'google'){
        anchor.href = window.$A.Env.apiHost+'/login/sso/google';
      }

      if(ssoMethod === 'microsoft'){
        anchor.href = window.$A.Env.apiHost+'/login/sso/microsoft';
      }
      document.body.appendChild(anchor);
      anchor.click();
    },
    async LogIn() {
      if (this.rememberMe) {
        $A.SetRememberMe(this.username);
      }
      this.working = true;
      await $A.UserService.Login(this.username, this.password);
      this.working = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style scoped>
.login-page {
  background-color: #019255;
}

.account-logo {
  width: 50px;
  height: 50px;
}

.auth-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

.auth-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.auth-or {
  background: white;
  width: 40px;
  left: calc(50% - 29px);
  text-align: center;
  position: absolute;
  top: -10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #6B7280;
}

.auth-or-line {
  height: 1px;
  background: #D1D5DB;
}

.auth-or-container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.generic-sidebar-auth {
  background: #ffffff;
  border-radius: 10px;
  padding: 32px 40px;
}

.auth-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.auth-green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.white-auth-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  padding: 9px 17px;
  height: 38px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.svg-btns {
  width: -webkit-fill-available;
}

.svg-btns:hover {
  opacity: 0.9;
}

.auth-green-btn, auth-gray-btn, white-auth-btn:hover {
  opacity: 0.9;
}

</style>

<template>
  <div>
    <div class="login-page registration-page">
      <div class="container-fluid">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-lg-4 col-md-5 m-auto">
            <div class="generic-sidebar-auth">
              <div v-if="accountLogo" class="ashore-logo-registration">
                <img :src="accountLogo" class="account-logo"/>
              </div>

              <div class="auth-header-text my-4">{{isCustomDomain ? 'Login' : 'Login to Ashore'}}</div>

              <!-- Google Btn -->
              <div class="form-group">
                <a  @click="ssoClicked('google')">
                  <img src="img/google-login-btn.svg" class="svg-btns"/>
                </a>
              </div>

              <!-- Microsoft Btn -->
              <div class="form-group">
                <a @click="ssoClicked('microsoft')">
                  <img src="img/ms-login-btn.svg" class="svg-btns"/>
                </a>
              </div>

              <div class="auth-or-container form-group">
                <div class="auth-or">Or</div>
                <hr class="auth-or-line"/>
              </div>
              <div class="form-group">
                <label class="auth-sub-text">Your Email:</label>
                <input
                    type="text"
                    name="Username"
                    class="form-control auth-input"
                    v-model="username"
                />
              </div>
              <div class="form-group">
                <label class="auth-sub-text">Your Password:</label>
                <input
                    type="password"
                    name="Password"
                    class="form-control auth-input"
                    v-model="password"
                    v-on:keyup.enter="LogIn()"
                />
              </div>
              <div class="ashore-toggle">
                <label class="switch">
                  <input type="checkbox" v-model="rememberMe"/>
                  <span class="slider round"></span>
                </label>
                Remember Me
              </div>
              <button
                  v-on:click="LogIn()"
                  data-cy="LogInBtn"
                  class="auth-green-btn button w-100 mt-4">
                <b-spinner
                    variant="success"
                    v-show="working"
                    type="grow"
                    small
                ></b-spinner>
                Login
              </button>
              <b-alert
                  :show="dismissCountDown"
                  dismissible
                  :variant="variant"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                  style="margin-top: 25px"
              >
                <p>{{ errorMessage }}</p>
                <b-progress
                    :variant="variant"
                    :max="dismissSecs"
                    :value="dismissCountDown"
                    height="4px"
                ></b-progress>
              </b-alert>
              <a href="/forgot-password" class="white-auth-btn button w-100 my-3">
                Forgot My Password
              </a>
              <a v-if="!isCustomDomain" href="/sign-up" class=" white-auth-btn button w-100">
                Create an Account
              </a>
            </div>
          </div>
          <!-- /Sidebar -->
          <!-- Elements -->
          <!-- /Elements -->
        </div>
      </div>
    </div>
  </div>
</template>
